import React from "react";
import {
  AIWorkforceIcon,
  ActionIcon,
  KnowledgeIcon,
  ModelIcon,
} from "../../Icons/Icons";
import { useSelector } from "react-redux";
import { isPlatformAdminUser } from "../../utils/utils";

export const useMenu = () => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);

  const menu = {
    key: [
      {
        name: "Dashboard",
        icon: <i className="menu-icon tf-icons ti ti-smart-home" />,
        link: "/",
        hasPermission: true,
      },
      {
        name: "Users",
        icon: <i className="menu-icon tf-icons ti ti-users" />,
        link: "/user-management",
        hasPermission: isPlatformAdmin,
      },
      {
        name: "Space",
        icon: <i className="menu-icon tf-icons ti ti-layout-grid" />,
        link: "/manage-space",
        hasPermission: true,
        // submenu: [
        //   {
        //     name: "Select Space",
        //     link: "/space/select-space",
        //   },
        //   {
        //     name: "Manage Space",
        //     link: "/space/manage-space",
        //   },
        // ],
      },
    ],
    "Space Gallery": [
      {
        name: "Teams",
        icon: <AIWorkforceIcon />,
        link: "/ai-workforce",
        hasPermission: true,
      },
      {
        name: "Agents",
        icon: <i className="menu-icon tf-icons ti ti-users" />,
        link: "/agents",
        hasPermission: true,
      },
      {
        name: "Actions",
        icon: <ActionIcon />,
        link: "/actions",
        hasPermission: true,
      },
      {
        name: "Models",
        icon: <ModelIcon />,
        link: "/models",
        hasPermission: true,
      },
      {
        name: "Databases",
        icon: <KnowledgeIcon />,
        link: "/database",
        hasPermission: true,
      },
      {
        name: "Collections",
        icon: <i className="menu-icon tf-icons ti ti-folder" />,
        link: "/collections",
        hasPermission: true,
      },
    ],
    // {
    //   name: "Environmental Variables",
    //   icon: <i class="menu-icon tf-icons ti ti-key"></i>,
    //   link: "/environmental-variables",
    // },
    // {
    //   name: "ML Projects",
    //   icon: <i className="menu-icon tf-icons ti ti-users" />,
    // },
    // "Shared Space": [
    //   {
    //     name: "Page 2",
    //     icon: <AIWorkforceIcon />,
    //   },
    //   // {
    //   //   name: "Agents",
    //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
    //   // },
    //   // {
    //   //   name: "Actions",
    //   //   icon: <ActionIcon />,
    //   // },
    //   // {
    //   //   name: "Models",
    //   //   icon: <ModelIcon />,
    //   // },
    //   // {
    //   //   name: "Knowledge",
    //   //   icon: <KnowledgeIcon />,
    //   // },
    //   // {
    //   //   name: "ML Projects",
    //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
    //   // },
    // ],
    Others: [
      {
        name: "Analytics",
        icon: (
          <i className="menu-icon tf-icons  ti ti-brand-google-analytics" />
        ),
        link: "/analytics",
        hasPermission: true,
      },
    ],
  };

  const filteredMenu = Object.fromEntries(
    Object.entries(menu).map(([key, items]) => [
      key,
      items.filter((item) => item.hasPermission),
    ])
  );
  return filteredMenu;
};
